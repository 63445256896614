import Vue from 'vue'
import Vuex from 'vuex'
import { userUserInfo, userUserRule } from '@/api/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,

    userRule: [],
    menus: null,

    orderId: null
  },
  getters: {},
  mutations: {
    // token
    SET_TOKEN: (state, token) => {
      state.token = token
      window.localStorage.setItem('token', token)
    },
    REMOVE_TOKEN: (state) => {
      state.token = null
      window.localStorage.removeItem('token')
    },

    // userInfo
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
      window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
    },
    REMOVE_USERINFO: (state) => {
      state.userInfo = null
      window.localStorage.removeItem('userInfo')
    },

    // routes
    SET_USERROUTES: (state, userRule) => {
      state.userRule = userRule
      window.localStorage.setItem('userRule', JSON.stringify(userRule))
    },
    REMOVE_USERROUTES: (state) => {
      state.userRule = null
      window.localStorage.removeItem('userRule')
    },

    // menus
    SET_MENUS: (state, menus) => {
      state.menus = menus
      window.localStorage.setItem('menus', JSON.stringify(menus))
    },
    REMOVE_MENUS: (state) => {
      state.menus = null
      window.localStorage.removeItem('menus')
    },

    // orderId
    SET_ORDERID: (state, id) => {
      state.orderId = id
    },
    REMOVE_ORDERID: (state, id) => {
      state.orderId = null
    }
  },
  actions: {
    // 获取用户信息
    getUserUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        userUserInfo()
          .then((res) => {
            commit('SET_USERINFO', res.data)
            resolve(res.data)
          })
          .catch((e) => {
            reject()
          })
      })
    },
    // 获取后端权限生成动态菜单
    getUserUserRuleList({ state, commit }) {
      return new Promise((resolve, reject) => {
        userUserRule()
          .then((res) => {
            const userInfo = state.userInfo
            let routesList = []
            if (userInfo.group_id == 1) {
              routesList = [
                {
                  title: '商家配置',
                  icon: 'setting',
                  path: '/setting',
                  component: () => import(`@/views/setting/index`),
                  meta: { title: '商家配置' },
                },
              ]
            }
            let userRule = res.data
            commit('SET_USERROUTES', userRule)

            let userRuleList = userRule.filter((item) => item.remark)
            for (let i = 0; i < userRuleList.length; i++) {
              let remark = userRuleList[i].remark.split('-')
              if (remark[1] == 1) {
                if (!userRuleList[i].show) {
                  routesList.push({
                    title: userRuleList[i].title,
                    icon: userRuleList[i].icon,
                    path: remark[0],
                    component: () => import(`@/views${remark[0]}/index`),
                    meta: { title: userRuleList[i].title },
                  })
                }
              } else {
                let subordinate = userRuleList[i].subordinate
                let children = []
                for (let a = 0; a < subordinate.length; a++) {
                  if (!subordinate[a].show) {
                    children.push({
                      path: subordinate[a].remark,
                      component: () =>
                        import(`@/views${subordinate[a].remark}`),
                      meta: {
                        title: subordinate[a].title,
                        activeMenu: remark[0],
                      },
                    })
                  }
                }
                routesList.push({
                  title: userRuleList[i].title,
                  icon: userRuleList[i].icon,
                  path: remark[0],
                  redirect: children[0].path,
                  component: () => import(`@/views${remark[0]}/box`),
                  children,
                })
              }
            }
            commit('SET_MENUS', routesList)
            resolve(routesList)
          })
          .catch((e) => {
            reject()
          })
      })
    },
    // 根据页面获取权限
    getPermission({ state }, path) {
      return new Promise((resolve, reject) => {
        let userRule = state.userRule
        let permission = userRule.filter((item) => item.extend == path)
        let permissionList = []
        if (permission.length) {
          let permissionItem = permission[0].subordinate
          permissionList = permissionItem.map((item) => {
            return {
              name: item.name,
              status: item.status == 'normal' && !item.show ? true : false,
            }
          })
        } else {
          let permissionTwo = userRule.map((item) =>
            item.subordinate.filter((fItem) => fItem.remark == path)
          )
          let permissionTwoItem = permissionTwo.filter((item) => item.length)[0]
          if(permissionTwoItem && permissionTwoItem.length) {
            let permissionItem = permissionTwoItem[0].subordinate
            permissionList = permissionItem.map((item) => {
              return {
                name: item.name,
                status: item.status == 'normal' && !item.show ? true : false,
              }
            })
          }
        }
        resolve(
          permissionList.length ? permissionList.reduce((obj, item) => ({ ...obj, [item.name]: item.status }), {}) : null
        )
      })
    },
    // 退出登录
    outLoginClick({ commit }) {
      commit('REMOVE_TOKEN')
      commit('REMOVE_USERINFO')
      commit('REMOVE_USERROUTES')
      commit('REMOVE_MENUS')
    },
    // 如有缓存直接赋值vuex 不去请求接口
    loadLocal({ commit }) {
      const token = window.localStorage.getItem('token')
      if (token && token != 'undefined') {
        commit('SET_TOKEN', token)
      }
      const userInfo = window.localStorage.getItem('userInfo')
      if (userInfo && userInfo != 'undefined') {
        commit('SET_USERINFO', JSON.parse(userInfo))
      }
      const userRule = window.localStorage.getItem('userRule')
      if (userRule && userRule != 'undefined') {
        commit('SET_USERROUTES', JSON.parse(userRule))
      }
      const menus = window.localStorage.getItem('menus')
      if (menus && menus != 'undefined') {
        commit('SET_MENUS', JSON.parse(menus))
      }
    },
  },
  modules: {},
})
