import request from '@/utils/request'

// 登录
export function userLogin(data) {
  return request({
    url: "/api/user/login",
    method: "post",
    data,
  });
}

// 用户详情
export function userUserInfo(data) {
  return request({
    url: "/api/user/user_info",
    method: "post",
    data,
  });
}

// 仓库列表
export function storehouseList(params) {
  return request({
    url: "/api/user/storehouse_list",
    method: "GET",
    params,
  });
}

// 仓库添加
export function storehouseAdd(data) {
  return request({
    url: "/api/user/storehouse_add",
    method: "POST",
    data,
  });
}

// 仓库修改
export function storehouseSave(data) {
  return request({
    url: "/api/user/storehouse_save",
    method: "POST",
    data,
  });
}

// 仓库修改
export function storehouseDel(data) {
  return request({
    url: "/api/user/storehouse_del",
    method: "POST",
    data,
  });
}

// 库存列表
export function stockList(data) {
  return request({
    url: "/api/user/stock_list",
    method: "POST",
    data,
  });
}

// 库存添加
export function stockAdd(data) {
  return request({
    url: "/api/user/stock_add",
    method: "POST",
    data,
  });
}

// 库存修改
export function stockSave(data) {
  return request({
    url: "/api/user/stock_save",
    method: "POST",
    data,
  });
}

// 库存删除
export function stockDel(data) {
  return request({
    url: "/api/user/stock_del",
    method: "POST",
    data,
  });
}

// 货品方列表
export function supplierList(data) {
  return request({
    url: "/api/user/supplier_list",
    method: "POST",
    data,
  });
}

// 货品方添加
export function supplierAdd(data) {
  return request({
    url: "/api/user/supplier_add",
    method: "POST",
    data,
  });
}

// 货品方修改
export function supplierSave(data) {
  return request({
    url: "/api/user/supplier_save",
    method: "POST",
    data,
  });
}

// 货品方删除
export function supplierDel(data) {
  return request({
    url: "/api/user/supplier_del",
    method: "POST",
    data,
  });
}

// 商品分类列表
export function goodsClassList(params) {
  return request({
    url: "/api/user/goods_class_list",
    method: "GET",
    params,
  });
}

// 商品分类添加
export function goodsClassAdd(params) {
  return request({
    url: "/api/user/goods_class_add",
    method: "POST",
    params,
  });
}

// 商品分类修改
export function goodsClassSave(params) {
  return request({
    url: "/api/user/goods_class_save",
    method: "POST",
    params,
  });
}

// 商品分类删除
export function goodsClassDel(params) {
  return request({
    url: "/api/user/goods_class_del",
    method: "POST",
    params,
  });
}

// 商品列表
export function goodsList(data) {
  return request({
    url: "/api/user/goods_list",
    method: "POST",
    data,
  });
}

// 商品添加
export function goodsAdd(data) {
  return request({
    url: "/api/user/goods_add",
    method: "POST",
    data,
  });
}

// 商品修改
export function goodsSave(data) {
  return request({
    url: "/api/user/goods_save",
    method: "POST",
    data,
  });
}

// 商品删除
export function goodsDel(data) {
  return request({
    url: "/api/user/goods_del",
    method: "POST",
    data,
  });
}

// 库存统计
export function inventoryStatistics(data) {
  return request({
    url: "/api/user/inventory_statistics",
    method: "POST",
    data,
  });
}

// 业绩统计
export function customerServiceStatistics(data) {
  return request({
    url: "/api/user/customer_service_statistics",
    method: "POST",
    data,
  });
}

// 平台列表
export function platformList(data) {
  return request({
    url: "/api/user/platform_list",
    method: "POST",
    data,
  });
}

// 平台添加
export function platformAdd(data) {
  return request({
    url: "/api/user/platform_add",
    method: "POST",
    data,
  });
}

// 平台修改
export function platformSave(data) {
  return request({
    url: "/api/user/platform_save",
    method: "POST",
    data,
  });
}

// 平台修改
export function platformDel(data) {
  return request({
    url: "/api/user/platform_del",
    method: "POST",
    data,
  });
}

// 订单列表
export function orderList(data) {
  return request({
    url: "/api/user/order_list",
    method: "POST",
    data,
  });
}

// 订单新增
export function orderAdd(data) {
  return request({
    url: "/api/user/order_add",
    method: "POST",
    data,
  });
}

// 订单修改
export function orderSave(data) {
  return request({
    url: "/api/user/order_save",
    method: "POST",
    data,
  });
}

// 订单删除
export function orderDel(data) {
  return request({
    url: "/api/user/order_del",
    method: "POST",
    data,
  });
}

// 订单续租
export function orderRenewLease(data) {
  return request({
    url: "/api/user/order_renew_lease",
    method: "POST",
    data,
  });
}

// 订单发货
export function orderShipping(data) {
  return request({
    url: "/api/user/order_shipping",
    method: "POST",
    data,
  });
}

// 订单归还确认
export function orderReturn(data) {
  return request({
    url: "/api/user/order_return",
    method: "POST",
    data,
  });
}

// 订单收货
export function orderReceipt(data) {
  return request({
    url: "/api/user/order_receipt",
    method: "POST",
    data,
  });
}

// 订单结算账单
export function orderSettlementCalculate(data) {
  return request({
    url: "/api/user/order_settlement_calculate",
    method: "POST",
    data,
  });
}

// 订单结算
export function orderSettlement(data) {
  return request({
    url: "/api/user/order_settlement",
    method: "POST",
    data,
  });
}

// 订单发货撤回
export function orderWithdraw(data) {
  return request({
    url: "/api/user/order_withdraw",
    method: "POST",
    data,
  });
}

// 订单备注添加
export function orderRemarkAdd(data) {
  return request({
    url: "/api/user/order_remark_add",
    method: "POST",
    data,
  });
}

// 订单备注列表
export function orderRemarkList(data) {
  return request({
    url: "/api/user/order_remark_list",
    method: "POST",
    data,
  });
}

// 快递列表
export function expressList(data) {
  return request({
    url: "/api/user/express_list",
    method: "POST",
    data,
  });
}

// 快递添加
export function expressAdd(data) {
  return request({
    url: "/api/user/express_add",
    method: "POST",
    data,
  });
}

// 快递修改
export function expressSave(data) {
  return request({
    url: "/api/user/express_save",
    method: "POST",
    data,
  });
}

// 快递删除
export function expressDel(data) {
  return request({
    url: "/api/user/express_del",
    method: "POST",
    data,
  });
}

// 押金方式列表
export function depositList(data) {
  return request({
    url: "/api/user/deposit_list",
    method: "POST",
    data,
  });
}

// 押金方式添加
export function depositAdd(data) {
  return request({
    url: "/api/user/deposit_add",
    method: "POST",
    data,
  });
}

// 押金方式修改
export function depositSave(data) {
  return request({
    url: "/api/user/deposit_save",
    method: "POST",
    data,
  });
}

// 押金方式删除
export function depositDel(data) {
  return request({
    url: "/api/user/deposit_del",
    method: "POST",
    data,
  });
}

// 权限列表
export function userUserRule(data) {
  return request({
    url: "/api/user/user_rule",
    method: "POST",
    data,
  });
}

// 发货配置列表
export function provinceList(data) {
  return request({
    url: "/api/user/province_list",
    method: "POST",
    data,
  });
}

// 发货添加
export function provinceAdd(data) {
  return request({
    url: "/api/user/province_add",
    method: "POST",
    data,
  });
}

// 发货修改
export function provinceSave(data) {
  return request({
    url: "/api/user/province_save",
    method: "POST",
    data,
  });
}

// 发货删除
export function provinceDel(data) {
  return request({
    url: "/api/user/province_del",
    method: "POST",
    data,
  });
}

// 修改商家配置
export function updateMerchant(data) {
  return request({
    url: "/api/user/update_merchant",
    method: "POST",
    data,
  });
}

// 用户分组列表
export function userGroupIndex(data) {
  return request({
    url: "/api/user/user_group_index",
    method: "POST",
    data,
  });
}

// 用户分组添加
export function userGroupAdd(data) {
  return request({
    url: "/api/user/user_group_add",
    method: "POST",
    data,
  });
}

// 用户分组编辑
export function userGroupSave(data) {
  return request({
    url: "/api/user/user_group_save",
    method: "POST",
    data,
  });
}

// 用户分组删除
export function userGroupDel(data) {
  return request({
    url: "/api/user/user_group_del",
    method: "POST",
    data,
  });
}

// 付款方式列表
export function payModuleList(data) {
  return request({
    url: "/api/user/pay_module_list",
    method: "POST",
    data,
  });
}

// 付款方式添加
export function payModuleAdd(data) {
  return request({
    url: "/api/user/pay_module_add",
    method: "POST",
    data,
  });
}

// 付款方式编辑
export function payModuleSave(data) {
  return request({
    url: "/api/user/pay_module_save",
    method: "POST",
    data,
  });
}

// 付款方式删除
export function payModuleDel(data) {
  return request({
    url: "/api/user/pay_module_del",
    method: "POST",
    data,
  });
}

// 员工列表
export function userList(data) {
  return request({
    url: "/api/user/user_list",
    method: "POST",
    data,
  });
}

// 员工添加
export function userAdd(data) {
  return request({
    url: "/api/user/user_add",
    method: "POST",
    data,
  });
}

// 员工编辑
export function userSave(data) {
  return request({
    url: "/api/user/user_save",
    method: "POST",
    data,
  });
}

// 员工禁用/启用
export function userDel(data) {
  return request({
    url: "/api/user/user_del",
    method: "POST",
    data,
  });
}

// 分润记录
export function userMoneyLogList(data) {
  return request({
    url: "/api/user/user_money_log_list",
    method: "POST",
    data,
  });
}