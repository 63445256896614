import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/order',
    component: () => import('../views/index'),
    children: [
      {
        path: '/goods',
        component: () => import('@/views/goods/index'),
        meta: { title: '产品管理' },
      },
      {
        path: '/storehouse',
        redirect: '/storehouse/index',
        component: () => import('@/views/storehouse/box'),
        children: [
          {
            path: '/storehouse/index',
            component: () => import('@/views/storehouse/index'),
            meta: { title: '仓库管理', activeMenu: '/storehouse' },
          },
          {
            path: '/storehouse/stock',
            component: () => import('@/views/storehouse/stock'),
            meta: { title: '库存列表', activeMenu: '/storehouse' },
          },
          {
            path: '/storehouse/supplier',
            component: () => import('@/views/storehouse/supplier'),
            meta: { title: '货品方列表', activeMenu: '/storehouse' },
          },
        ],
      },
      {
        path: '/order',
        redirect: '/order/index',
        component: () => import('@/views/order/box'),
        children: [
          {
            path: '/order/index',
            component: () => import('@/views/order/index'),
            meta: { title: '订单列表', activeMenu: '/order' },
          },
          {
            path: '/order/platform',
            component: () => import('@/views/order/platform'),
            meta: { title: '渠道管理', activeMenu: '/order' },
          },
          {
            path: '/order/statistics',
            component: () => import('@/views/order/statistics'),
            meta: { title: '库存统计', activeMenu: '/order' },
          },
          {
            path: '/order/serviceStatistics',
            component: () => import('@/views/order/serviceStatistics'),
            meta: { title: '业绩统计', activeMenu: '/order' },
          },
        ],
      },
      {
        path: '/express',
        component: () => import('@/views/express/index'),
        meta: { title: '快递管理' },
      },
      {
        path: '/deposit',
        component: () => import('@/views/deposit/index'),
        meta: { title: '押金方式' },
      },
      {
        path: '/province',
        component: () => import('@/views/province/index'),
        meta: { title: '发货配置' },
      },
      {
        path: '/permission',
        component: () => import('@/views/permission/index'),
        meta: { title: '权限管理' },
      },
      {
        path: '/user',
        component: () => import('@/views/user/index'),
        meta: { title: '员工管理' },
      },
      {
        path: '/setting',
        component: () => import('@/views/setting/index'),
        meta: { title: '商家配置' },
      },
      {
        path: '/paymodule',
        component: () => import('@/views/paymodule/index'),
        meta: { title: '付款方式' },
      },
      {
        path: '/userMoneyLog',
        component: () => import('@/views/userMoneyLog/index'),
        meta: { title: '分润管理' },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login'),
  },
  {
    path: '*',
    component: () => import('../views/error/404'),
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  let token = store.state.token
  if (token) {
    next()
  } else {
    if (to.path === '/login') {
      next()
    } else {
      next('/login')
    }
  }
})

export default router
