<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style lang="scss">
.app-container {
  padding: 0 20px;
  background-color: #fff;
  min-height: calc(100vh - 88px);
}
</style>
