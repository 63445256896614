import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import plugins from './plugins' // plugins
Vue.use(plugins)

// ant-design-vue
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
Vue.use(Antd)

import { message } from 'ant-design-vue';
Vue.prototype.$message = message;

// 公共样式
import '@/assets/styles/index.scss' // global css

// 查看大图
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
Vue.use(Viewer);

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

const dayjs = require('dayjs')
Vue.prototype.$dayjs = dayjs

store.dispatch("loadLocal");

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
